<template>
  <div class="top-content">
    <div class="page-header width-100p">
      <a-row class="d-flex align-items-center mb-3" style="margin: -30px; padding: 22px 30px; background: #fff;">
        <a-col :span="6">
          <h3 class="my-2 font-size-21">Top matches</h3>
        </a-col>
        <a-col :span="18" class="text-right" style="display: flex; justify-content: flex-end;">
          <a-tooltip class="mr-4" title="Refresh Top" placement="bottom">
            <a href="javascript:" @click="getMatches" class="text-primary font-size-24">
              <a-icon type="sync" :spin="sectionsLoading" style="margin-top: 8px" />
            </a>
          </a-tooltip>
          <div class="list-sections__row">
            <a-tooltip title="Add matches section" placement="bottom">
              <a-button type="primary" class="ml-2" size="large" @click="createMatchModal">
                <a-icon type="plus" /> Matches
              </a-button>
            </a-tooltip>
            <a-tooltip title="Delete old matches" placement="bottom">
              <a-popconfirm
                title="Sure to delete?"
                @confirm="deleteOldMatches"
              >
                <a-button type="danger" icon="delete" class="ml-2" size="large">
                  Delete old
                </a-button>
              </a-popconfirm>
            </a-tooltip>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="text-center w-100 font-size-24">
      <a-spin class="flex m-auto mt-2" size="large" v-if="sectionsLoading" />
    </div>
    <match-section
      v-if="allMatches.length"
      :matches-data="allMatches"
      @createMatchModal="createMatchModal"
      @editMatchModal="editMatchModal"
    />
    <a-modal
      v-model="matchModal"
      :title="matchObj && matchObj.id ? `Edit match [#${matchObj.id}]` : 'Add match'"
      :footer="null"
      class="match-modal"
      width="480px"
      :destroyOnClose="true"
    >
      <match-modal
        @createMatch="handleCreateMatch"
        @updateMatch="handleUpdateMatch"
        @deleteMatch="handleDeleteMatch"
        @closeModalFunction="matchModal = false"
        :match-obj="matchObj"
        :edit="matchObj && matchObj.id ? true : false"
        ref="matchModal"
        />
    </a-modal>
    <a-modal
      v-model="logsModal"
      :title="matchObj && matchObj.id ? `Match [#${matchObj.id}] logs ` : ''"
      :footer="null"
      class="match-logs"
      width="800px"
      :destroyOnClose="true"
    >
      <logs-modal
        @closeModalFunction="logsModal = false"
        :match-obj="matchObj"
        ref="logsModal"
        />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import matchSection from './matchSection'
import matchModal from '@/components/custom/modals/matchModal.vue'
import logsModal from './modals/logsModal.vue'
import accessMix from '@/services/accessmix.js'
import TopMatchesService from '@/services/api/apiTopMatchesService'

export default {
  name: 'index',
  components: {
    matchModal,
    logsModal,
    matchSection,
  },
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    allMatches() {
      return this.matches
    },
  },
  data() {
    return {
      accessModule: 'top',
      matches: [],
      sectionsLoading: false,
      refreshCount: 0,
      drag: false,
      matchObj: undefined,
      matchModal: false,
      logsModal: false,
    }
  },
  created () {
    this.$root.$on('openLogModal', (obj) => {
      this.openLogModal(obj)
    })
  },
  mounted () {
    this.getMatches()
  },
  methods: {
    async getMatches() {
      this.sectionsLoading = true
      return TopMatchesService.getMatches().then((response) => {
        this.refreshCount++
        this.matches = response.data.data
        this.matches.sort((a, b) => { return b.is_active - a.is_active || b.weight - a.weight || a.starts_t - b.starts_t || a.match_id - b.match_id })
        this.sectionsLoading = false
      }).catch(error => {
        console.log(error)
        this.sectionsLoading = false
      })
    },
    deleteOldMatches() {
      this.sectionsLoading = true
      return TopMatchesService.deleteOldMatches().then((response) => {
        this.getMatches()
      }).catch(error => {
        console.log(error)
        this.sectionsLoading = false
      })
    },
    createMatchModal() {
      this.matchObj = null
      this.matchModal = true
    },
    editMatchModal(obj) {
      this.matchObj = obj
      this.matchModal = true
    },
    openLogModal(obj) {
      this.matchObj = obj
      this.logsModal = true
    },
    handleCreateMatch(created) {
      this.matchModal = false
      this.matches.unshift(created)
      this.matches.sort((a, b) => { return b.is_active - a.is_active || b.weight - a.weight || a.starts_t - b.starts_t || a.match_id - b.match_id })
    },
    handleUpdateMatch(updated) {
      const index = this.matches.findIndex((el) => el.id === updated.id)
      this.matches[index] = updated
      this.matches.sort((a, b) => { return b.is_active - a.is_active || b.weight - a.weight || a.starts_t - b.starts_t || a.match_id - b.match_id })
      this.matchModal = false
    },
    handleDeleteMatch(deletedId) {
      this.matches.splice(this.matches.findIndex(x => x.id === deletedId), 1)
      this.matchModal = false
    },
  },
}
</script>
<style lang="scss" src="./style.module.scss" />
